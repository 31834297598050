import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import NavPills from "components/NavPills/NavPills.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import pricingStyle from "assets/jss/material-kit-pro-react/views/pricingSections/pricingStyle.js";

const useStyles = makeStyles(pricingStyle);

export default function SectionPricing() {
  const classes = useStyles();
  return (
    <div className={classes.pricingSection}>
      <GridContainer>
        <GridItem
          md={6}
          sm={6}
          className={classNames(
            classes.mrAuto,
            classes.mlAuto,
            classes.textCenter
          )}
        >
          {/* <NavPills
            alignCenter
            color="info"
            tabs={[
              {
                tabButton: "monthly",
              },
              {
                tabButton: "yearly",
              },
            ]}
          /> */}
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem md={6} sm={6}>
          <Card plain pricing>
            <CardBody pricing>
              <h6
                className={classNames(classes.cardCategory, classes.textdanger)}
              >
                Starter Pack
              </h6>
              <h1 className={classes.cardTitle}>
                <small>$</small>240 <small>/mo</small>
              </h1>
              <ul>
                <li>
                  <b>1</b> New customized program weekly
                </li>
                <li>
                  <b>1</b> Monthly assesments
                </li>
                <li>
                  <b>1</b> Check in with our master trainer
                </li>
              </ul>
              <Button href="#pablo" color="info" round>
                Get started
              </Button>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem md={6} sm={6}>
          <Card raised pricing color="info">
            <CardBody pricing>
              <h6 className={classes.cardCategory}>Elite (Most Popular)</h6>
              <h1 className={classes.cardTitleWhite}>
                <small>$</small>435 <small>/mo</small>
              </h1>
              <ul>
                <li>
                  <b>2</b> New customized program weekly
                </li>
                <li>
                  <b>1 </b> Monthly assesment
                </li>
                <li>
                  <b>1</b> Check in with our master trainer
                </li>
              </ul>
              <Button href="#pablo" color="white" round>
                Get started
              </Button>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>

      <GridContainer>
        <GridItem
          md={6}
          sm={6}
          className={classNames(
            classes.mrAuto,
            classes.mlAuto,
            classes.textCenter
          )}
        >
          {/* <NavPills
            alignCenter
            color="info"
            tabs={[
              {
                tabButton: "monthly",
              },
              {
                tabButton: "yearly",
              },
            ]}
          /> */}
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem md={4} sm={4}>
          <Card plain pricing>
            <CardBody pricing>
              <h6
                className={classNames(classes.cardCategory, classes.textdanger)}
              >
                Advanced
              </h6>
              <h1 className={classes.cardTitle}>
                <small>$</small>585 <small>/mo</small>
              </h1>
              <ul>
                <li>
                  <b>3</b> New customized program weekly
                </li>
                <li>
                  <b>1</b> Monthly assesment
                </li>
                <li>
                  <b></b> 30 minute bi-weekly check in call with our master
                  trainer
                </li>
                <li>
                  <b></b> Customized recipes to maximize your goals
                </li>
              </ul>
              <Button href="#pablo" color="info" round>
                Get started
              </Button>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem md={4} sm={4}>
          <Card plain pricing>
            <CardBody pricing>
              <h6
                className={classNames(classes.cardCategory, classes.textdanger)}
              >
                Transformation
              </h6>
              <h1 className={classes.cardTitle}>
                <small>$</small>695 <small>/mo</small>
              </h1>
              <ul>
                <li>
                  <b>4</b> New customized program weekly
                </li>
                <li>
                  <b>1</b> Monthly assesment
                </li>
                <li>
                  <b></b> 30 minute bi-weekly check in call with our master
                  trainer
                </li>
                <li>
                  <b></b> Customized recipes to maximize your goals
                </li>
              </ul>
              <Button href="#pablo" color="info" round>
                Get started
              </Button>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem md={4} sm={4}>
          <Card plain pricing>
            <CardBody pricing>
              <h6
                className={classNames(classes.cardCategory, classes.textdanger)}
              >
                Lifestyle
              </h6>
              <h1 className={classes.cardTitle}>
                <small>$</small>760 <small>/mo</small>
              </h1>
              <ul>
                <li>
                  <b>5</b> New customized program weekly
                </li>
                <li>
                  <b>1</b> Monthly assesment
                </li>
                <li>
                  <b></b> 30 minute bi-weekly check in call with our master
                  trainer
                </li>
                <li>
                  <b></b> Customized recipes to maximize your goals
                </li>
                <li>
                  <b></b> Weekly nutrition counseling
                </li>
                <li>
                  <b></b> 24/7 support
                </li>
              </ul>
              <Button href="#pablo" color="info" round>
                Get started
              </Button>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}

import React from "react";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// library used for cool animations
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";

import contentStyle from "assets/jss/material-kit-pro-react/views/presentationSections/contentStyle.js";
// images
// import presentationiPad from "assets/img/assets-for-demo/presentationViewSectionComponent/presentation-ipad.jpg";
// import presentationiPadComments from "assets/img/assets-for-demo/presentationViewSectionComponent/ipad-comments.jpg";
// import presentationiPadTable from "assets/img/assets-for-demo/presentationViewSectionComponent/ipad-table.jpg";
import fitnessImage from "assets/img/fit7.png";

const useStyles = makeStyles(contentStyle);

export default function SectionContent() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem md={4}>
            <div className={classes.sectionDescription}>
              <h3 className={classes.title}>WITH YOU EVERY STEP OF THE WAY</h3>
              {/* <h6 className={classes.description}>
                For Areas that Need More Space
              </h6> */}
              <h5 className={classes.description}>
                The check-in calls allow us to make regular adjustments to your
                program based on your tracked progress. Our Nutritionist
                provides a range of services based on your package to further
                educate and assist you to transform your body from the inside
                out. Your success is our success, so we make it a priority.
              </h5>
            </div>
          </GridItem>
          <GridItem md={7} className={classes.mlAuto}>
            <div className={classes.imageContainer}>
              {/* <div className={classes.animeAreaImg}>
                <ScrollAnimation animateIn="animate__fadeInUp">
                  <img
                    src={fitnessImage}
                    alt="iPad comments"
                    className={classes.areaImg}
                  />
                </ScrollAnimation>
              </div>
              <div className={classes.animeInfoImg}>
                <ScrollAnimation animateIn="animate__fadeInUp">
                  <img
                    src={presentationiPadTable}
                    alt="iPad table"
                    className={classes.infoImg}
                  />
                </ScrollAnimation>
              </div> */}
              <ScrollAnimation animateIn="animate__fadeInUp">
                <img
                  className={classes.ipadImg}
                  src={fitnessImage}
                  alt="iPad"
                />
              </ScrollAnimation>
            </div>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}

import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// library used for cool animations
import ScrollAnimation from "react-animate-on-scroll";

import cardsStyle from "assets/jss/material-kit-pro-react/views/presentationSections/cardsStyle.js";

// import cardsTest from "assets/img/assets-for-demo/cards-test.png";
import fitnessImage from "assets/img/fit5.png";

const useStyles = makeStyles(cardsStyle);

export default function SectionCards() {
  const classes = useStyles();
  return (
    <div className={classNames(classes.section, classes.sectionDark)}>
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem md={7} sm={7}>
            <div className={classes.imageContainer2}>
              <ScrollAnimation animateIn="animate__fadeInRight">
                <img src={fitnessImage} alt="views" />
              </ScrollAnimation>
            </div>
          </GridItem>
          <GridItem md={4} sm={5} className={classes.mlAuto}>
            <div className={classes.sectionDescription}>
              <h3 className={classes.title}>TRY IT BEFORE YOU BUY IT</h3>
              {/* <h6 className={classes.description}>
                One Card for Every Problem
              </h6> */}
              <h5 className={classes.description}>
                While we assess your needs and goals to generate a plan for you,
                you get to experience our services in their entirety (maybe?).
                Best part? You forgo those excessive and endless contract and
                gym membership fees!
              </h5>
            </div>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}

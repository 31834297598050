import React from "react";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

// @material-ui icons
import Apps from "@material-ui/icons/Apps";
import ViewDay from "@material-ui/icons/ViewDay";
import ViewCarousel from "@material-ui/icons/ViewCarousel";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/presentationSections/descriptionStyle.js";

const useStyles = makeStyles(descriptionStyle);

export default function SectionDescription() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem md={8} sm={8}>
            <h4 className={classes.description}>
              Our philosophy is simple, every body is different. Our bodies
              genetic makeup is contingent upon things beyond our DNA sequence.
              Our age, cultural habits &amp; traits, lifestyle, and possible
              medical conditions play a vital role in the metabolic functions of
              our bodies.
            </h4>
            <h4 className={classes.description}>
              It’s inevitable to get discouraged if you’re attempting a
              one-size-fits-all approach to lead a healthy lifestyle. This is
              why we’re here. Straight A Fitness customizes each and every
              program to cater to your physical needs, fitness level, schedule,
              and wallet. So helps us help you create a path to your fitness
              goals that is just as unique as you are!
            </h4>
          </GridItem>
        </GridContainer>
        <div className={classes.features}>
          <GridContainer>
            <GridItem md={4} sm={4}>
              <InfoArea
                title="Customizable plans that will fit all fitness goals"
                icon={Apps}
                iconColor="danger"
                vertical={true}
              />
            </GridItem>
            <GridItem md={4} sm={4}>
              <InfoArea
                title="Gain Access to library Fitness programs to elevate your fitness journey"
                icon={ViewDay}
                iconColor="primary"
                vertical={true}
              />
            </GridItem>
            <GridItem md={4} sm={4}>
              <InfoArea
                title="Certified Trainers and Licensed Nutritionists"
                icon={ViewCarousel}
                iconColor="success"
                vertical={true}
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
